import React, { useEffect } from 'react';
import Viewer from 'react-viewer';

import { getImageUrl } from '../../utils/file';

const ImagemModal = ({id, titulo, ...props}) => {
  useEffect(() => {
    if(props.visible) {
        const handleDocumentClick = (element) => {
            const target = element.target;
            if (target instanceof HTMLElement && target.parentElement.classList.contains("react-viewer")) {
                props.onClose()
            }
        }

        document.addEventListener('click', handleDocumentClick)

        return () => {
            document.removeEventListener('click', handleDocumentClick)
        }
    }
}, [props, props.visible])
  
  const isBase64 = id?.includes('base64');
  return (
    <Viewer
      {...props}
      noNavbar
      noFooter
      showTotal={false}
      changeable={false}
      images={[{src: isBase64 ? id : getImageUrl(id), alt: `${titulo} `}]}
    />
  );
};

export default ImagemModal;
